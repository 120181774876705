import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Span } from "../../components/Core";
import { device } from "../../utils";

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;



const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
   
    margin: 0 auto;
   
  }
`;


const AcneHeroPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
   <Separator />
    <Section bg="#f7f7fb" py={4}>
      <Container className="pt-3 pb-3">

        <Row className="pb-4">
          <Col> b b
          <Text>
            Are you looking to collaborate? Look no further! Our clinic offers a range of cutting-edge treatments and luxurious services that will take your content to the next level.
               </Text>
          </Col>
        </Row>
      

        <Row className=" align-items-center">
       
            <Col lg="12" className="order-lg-2">
                <Secondtext className="pb-4">How do I apply for a model treatment?</Secondtext>
            <Text>
            Simply send us an email to info@dermamina.com with the following: <br /> <br />

Subject: Collaboration <br /> <br />
1. Full Name<br /> <br />
2. DOB<br /> <br />
3. Mobile number<br /> <br />
4. Consent for pictures to be posted?<br /> <br />
5. Treatment required<br /> <br />
6. Send us 3 clear pictures of your face<br /> 
(1 front and 2 from both sides)<br /> <br />
               </Text>

               <a href="mailto:info@dermamina.com" activeClassName="active">      
               <Bookbutton>
                Email Us
                
                </Bookbutton> </a>
            </Col>
           
           
        </Row>
   
      </Container>

    
     
    </Section>
    <SeparatorEnd />
    
  </>
);

export default AcneHeroPage;
